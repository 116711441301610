<template>
	<v-container
		class="branch list p-3"
		fill-height
	>
		<div
			style="width: 100%;"
		>
			<v-row
				align="center"
				justify="center"
			>
				<v-col 
					class="text-center"
				>
					<div>
						<v-icon 
							color="success2"
							class="text-h3"
						>mdi-account-search</v-icon>
					</div>
					<div
						class="mt-10 mb-10 text-caption"
					>
						새로운 영업점을 등록합니다.<br/>
						내 연락처에서 검색하거나 <br/>
						번호를 직접입력하여 <br/>
						영업점 등록 SMS를 발신할 수 있습니다. <br />
						아래 버튼을 통해 등록/관리 해보세요.
					</div>
					
					<v-btn
						color="success2"
						class="rounded-pill text-caption mr-5"
						width="40%"
						:to="'/Branch/Friends'"
					>
						연락처에서 선택
					</v-btn>
					
					<v-btn
						color="success2"
						class="rounded-pill text-caption"
						width="40%"
						:to="'/Branch/Guest'"
						outlined
					>
						번호로 직접 입력
					</v-btn>
					
				</v-col>
			</v-row>
		</div>
	</v-container>
</template>

<script>
	export default{
		name: 'Branch'
		,created: function(){
			this.$emit('setProgram', this.program)
			this.getData()
		}
		,props: ['callBack', 'user']
		,data: function(){
			return{
				program: {
					title: '영업점 관리'
				}
				,status: [
					{
						code: 'Normal'
						,codeName: '일반'
					}
					,{
						code: 'Pause'
						,codeName: '정지'
					}
					,{
						code: 'Termination'
						,codeName: '종료'
					}
				]
				,search: {
					sortType: 'JoinDt'
					,status: 'Normal'
				}
				,items: [
					
				]
				,temp: [
					{
						branchId: 1
						,branchName: '영업 담당자1'
						,branchPhoneNumber: '010-1234-5678'
						,branchStatus: 0
						,branchCreatedDate:  '20200814'
					}
					,{
						branchId: 2
						,branchName: '영업 담당자2'
						,branchPhoneNumber: '010-1234-5678'
						,branchStatus: 1
						,branchCreatedDate:  '20200814'
					}
					,{
						branchId: 3
						,branchName: '영업 담당자3'
						,branchPhoneNumber: '010-1234-5678'
						,branchStatus: 2
						,branchCreatedDate:  '20200814'
					}
					,{
						branchId: 4
						,branchName: '영업 담당자4'
						,branchPhoneNumber: '010-1234-5678'
						,branchStatus: 2
						,branchCreatedDate:  '20200814'
					}
					,{
						branchId: 5
						,branchName: '영업 담당자5'
						,branchPhoneNumber: '010-1234-5678'
						,branchStatus: 2
						,branchCreatedDate:  '20200814'
					}
				]
			}
		}
		,methods: {
			getData: function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/sales'
					,request_params: {
						sortType: 'Name'
						,status: 'Normal'
					}
					,authorize: true
					,callBack: 'result'
				})
			}
			,result: function(call){
				
				this.items = call.items.content
				this.items = this.temp
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					console.log('watch branch')
					console.log(call)
					if(call.name == 'result'){
						this.result(call)
					}
				}
			}
		}
	}
</script>

<style>
	.branch.list .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
		margin: 0;
		max-height: 32px;
		min-height: 30px !important;
		align-items: center!important
	}

	.branch.list .v-input__control input{
		max-height: 0px;
		min-height: 0px;
	}
	
	.branch.list .v-select__selection{
		min-height: 0px;
	}
	
	.branch.list .v-input__slot {
		padding: 0px 5px 0px 10px !important;
	}
	
	.branch.list .border-bottom:not(:last-child) {border-bottom: 1px solid #ddd;}
</style>
